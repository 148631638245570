import { Divider } from "@mui/material";
import separador from "../assets/separador.png";

const Separador = () => {
    return (
        <Divider sx={{ pt: 4 }} color="primary">
          <img src={separador} alt="separador" />
        </Divider>
    )
}

export default Separador;