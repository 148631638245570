import { Button, Grid, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import hero6 from "../assets/hero-background6.jpg";
import { useState } from "react";
import { generateURLForConsulta } from "../services/TextService";

const whiteInput = {
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiOutlinedInput-input": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};

const Contacto = () => {
  const [nombre, setNombre] = useState("");
  const [consulta, setConsulta] = useState("");

  const [nombreError, setNombreError] = useState(false);
  const [consultaError, setConsultaError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    setNombreError(nombre === "");
    setConsultaError(consulta === "");

    if (nombre && consulta) {
      window.open(generateURLForConsulta(nombre, consulta), "_blank");
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
        columns={{ xs: 1, md: 2 }}
        sx={{
          flexGrow: 1,
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${hero6})`,
          py: 10,
        }}
      >
        <Grid
          item
          xs={1}
          sx={{ display: "flex", flexDirection: "column", rowGap: 4, px: 10 }}
          component="form"
          onSubmit={handleSubmit}
          noValidate
        >
          <Typography
            id="contacto"
            variant="h2"
            align="center"
            color="white"
            gutterBottom
          >
            Contactános
          </Typography>
          <TextField
            id="nombre"
            label="Nombre"
            required
            error={nombreError}
            sx={{ ...whiteInput }}
            onChange={(e) => setNombre(e.target.value)}
          />
          <TextField
            id="consulta"
            label="Consulta"
            required
            error={consultaError}
            multiline
            rows={6}
            sx={{ ...whiteInput }}
            onChange={(e) => setConsulta(e.target.value)}
          />
          <Button
            variant="contained"
            size="large"
            endIcon={<SendIcon />}
            type="submit"
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Contacto;
