import { Box, Grid, Link, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import hero5 from "../assets/hero-background5.jpg";
import { useState } from "react";

const preguntas = [
  /*{
    pregunta: "¿Cuánto antes debo llegar para el turno en gabinete?",
    respuesta:
      "Te pedimos llegar 5 minutos antes para empezar en horario y aprovechar mejor el tiempo.",
  },*/
  {
    pregunta: "¿Cuánto duran las sesiones de masajes?",
    respuesta: "Las sesiones de masajes duran alrededor de 45 minutos",
  },
  {
    pregunta: "¿Cuánto duran las sesiones de depilación?",
    respuesta: "Va a depender de la zona a tratar, podés utilizar los formularios de consulta para que te contemos más detalles.",
  },
  {
    pregunta: "¿Hay algún impedimento para realizarme masajes?",
    respuesta: "Si estás en tratamiento oncológico, si tenes flebitis o estás en un estado febril, si tenés hipertensión o diabetes, o estas cursando un embarazo, no podés realizarte masajes.",
  },
  {
    pregunta: "¿Qué días y horarios trabajan?",
    respuesta: "Trabajamos de lunes a sábados, preferentemente por la tarde, pero consultános por turnos para la mañana.",
  },
  {
    pregunta: "¿Qué métodos de pago se aceptan?",
    respuesta: "Aceptamos efectivo, transferencia, cuenta DNI y Mercado Pago.",
  },
];

const FAQ = () => {
  const [question, setQuestion] = useState(0);

  return (
    <>
      <Typography variant="h2" align="center" color="primary">
        Preguntas frecuentes
      </Typography>
      <Typography gutterBottom variant="h5" component="div" align="center">
        Encontrá respuestas acá!
      </Typography>
      <Typography gutterBottom variant="subtitle1" component="div" align="center">
        Si tu duda no está contemplada en estas preguntas, podés contactarnos con tu consulta haciendo <Link component="a" href="#contacto">click acá</Link>
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        columns={{ sm: 1, md: 2 }}
        sx={{
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Grid
          item
          xs={1}
          sx={{
            display: { xs: "none", md: "block" },
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${hero5})`,
            minHeight: "50vh",
          }}
        ></Grid>
        <Grid
          item
          xs={1}
          sx={{
            minHeight: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ textAlign: "center", px: 4, mb: 4 }}>
            {preguntas.map((pregunta, index) => (
              <Accordion
                elevation={0}
                disableGutters
                sx={{
                  mt: 1,
                  "&:before": {
                    display: "none",
                  },
                }}
                expanded={index === question}
                onChange={() => setQuestion(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    backgroundColor: `${"primary"}.main`,
                    color: "white",
                    borderRadius: 50,
                  }}
                >
                  {pregunta.pregunta}
                </AccordionSummary>
                <AccordionDetails>{pregunta.respuesta}</AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FAQ;
