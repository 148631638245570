import { whatsapp } from "../vars";

export const generateURLForConsultaRapida = (
  nombre,
  motivo,
  tratamiento,
  tipo,
  zona
) => {
  let texto =
    "Hola, mi nombre es " +
    nombre +
    " y quería " +
    motivo +
    " por " +
    tratamiento +
    " " +
    tipo +
    " en " +
    zona +
    ".";

    return whatsapp + "&text=" + texto;
};

export const generateURLForGiftcard = (
  ) => {
    let texto = "Hola, me interesaría regalar uno de tus tatamientos a un ser querido, ¿qué opciones tenés disponibles?";
  
      return whatsapp + "&text=" + texto;
  };
  
  export const generateURLForConsulta = (
    nombre,
    consulta
  ) => {
    let texto =
      "Hola, mi nombre es " +
      nombre +
      ". " +
      consulta;
  
      return whatsapp + "&text=" + texto;
  };