import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";

import hero3 from "../assets/hero-background3.jpg";
import profile from "../assets/profile.jpg";

const AboutUs = () => {
  return (
    <>
      

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        columns={12}
        sx={{
          flexGrow: 1,
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${hero3})`,
          py: 10,
        }}
      >
        <Grid item xs={8} sm={6} md={4}>
        <Typography variant="h2" align="center" color="white" gutterBottom>
        Estás en buenas manos
      </Typography>
          <Card>
            <CardMedia
              sx={{ height: 400 }}
              image={profile}
              title="Sergio Gómez"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" color="primary">
                Sergio Gómez
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Masajista corporal, titulo emitido por instituto "Super
                Profesional" matrícula privada Nº1122. Masajes con piedras calientes, en el mismo instituto.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Masajista deportivo por el instituto "Exequiel García Estudio",
                matrícula Nº1533 por OMEE (Organización Mundial de Estilismo).
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Depilación sistema español y descartable, título expedido por
                CIMA, con registro matricular 673642.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AboutUs;
