import { CardHeader, Chip, Grid, IconButton, Typography } from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import tratamientos from "../tratamientos";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import YouTubeIcon from "@mui/icons-material/YouTube";

import descripciones from "../tratamientos.desc";

const Servicios = () => {
  return (
    <>
      <Typography variant="h2" align="center" color="primary" gutterBottom>
        Servicios
      </Typography>

      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          p: 4,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          columns={{ xs: 1, sm: 4, md: 8, lg: 12 }}
        >
          {tratamientos.map((tratamiento) =>
            tratamiento.tipos.map((tipo) => (
              <Grid item xs={1} sm={2} md={4} key={tipo}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "300px",
                  }}
                >
                  <CardHeader
                    sx={{ backgroundColor: `${"primary"}.main` }}
                    title={
                      <Typography
                        variant="h5"
                        sx={{
                          textTransform: "lowercase",
                          ":first-letter": { textTransform: "uppercase" },
                        }}
                        color="white"
                      >
                        {tratamiento.tratamiento} {tipo.tipo}
                      </Typography>
                    }
                    {...((tratamiento.observaciones || tipo.observaciones) && {
                      subheader:
                        "* " +
                        [tratamiento.observaciones, tipo.observaciones]
                          .filter((e) => e)
                          .join(", "),
                    })}
                    subheaderTypographyProps={{
                      color: "white",
                      variant: "caption",
                    }}
                    {...(tipo.videoURL && {
                      action: (
                        <IconButton
                          component="a"
                          href={tipo.videoURL}
                          target="_blank"
                          sx={{ p: 0.3 }}
                        >
                          <YouTubeIcon
                            sx={{ color: "white" }}
                            fontSize="large"
                          />
                        </IconButton>
                      ),
                    })}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="body2">
                      {descripciones[`${tratamiento.tratamiento} ${tipo.tipo}`]}
                    </Typography>
                  </CardContent>

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{ pl: 1, pb: 1, gap: 1 }}
                  >
                    {tipo.zonas.map((zona) => (
                      <Grid key={zona} item xs="auto">
                        <Chip label={zona} variant="outlined" color="primary" />
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Servicios;
