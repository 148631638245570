import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import hero2 from "../assets/hero-background2.jpg";

const Hero = () => {
  return (
    <Grid
      container
      sx={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: {xs: "left", lg: "center"},
        backgroundImage: `url(${hero2})`,
      }}
    >
      <Grid item md={6}>
        <Box
          sx={{
            p: { xs: 3, md: 6 },
            pr: { md: 0 },
          }}
        >
          <Typography
            variant="h2"
            align="left"
            color="text.primary"
            gutterBottom
          >
            Tratamientos de relajación y cuidado personal
          </Typography>

          <Typography
            variant="subtitle1"
            align="left"
            color="text.secondary"
            paragraph
          >
            En nuestro refugio dedicado al bienestar, te invitamos a sumergirte
            en un mundo de relajación y cuidado personal. Nuestros expertos
            terapeutas ofrecen una experiencia única, fusionando la serenidad de
            los masajes con la suavidad de la depilación para que te sientas
            renovado y radiante. Con técnicas tradicionales y productos de
            calidad, cada visita es una oportunidad para revitalizar tu cuerpo y
            rejuvenecer tu espíritu. Bienvenido a un espacio donde el
            autocuidado se convierte en un ritual, y cada detalle está diseñado
            para nutrir tu ser. ¡Descubre el equilibrio perfecto entre
            relajación y belleza con nosotros!
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
