import { IconButton, Stack, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";

import logo from "../assets/logo.png";
import { instagram, telefono, whatsapp } from "../vars";

const Header = () => {
  return (
    <AppBar position="relative">
      <Toolbar sx={{ py: 1 }}>
        <img src={logo} alt="logo"></img>
        <Typography
          variant="h3"
          color="inherit"
          noWrap
          sx={{ pt: 1, pl: 2, flexGrow: 1 }}
        >
          Sergio
        </Typography>

        <Stack gap={1.5} direction="row">
          <IconButton
            component="a"
            href={instagram}
            target="_blank"
            sx={{ color: "white", backgroundColor: "#c32aa3" }}
          >
            <InstagramIcon />
          </IconButton>

          <IconButton
            sx={{ backgroundColor: "#25d366", color: "white" }}
            component="a"
            href={whatsapp}
            target="_blank"
          >
            <WhatsAppIcon />
          </IconButton>

          <IconButton
            sx={{ backgroundColor: "#4285f4", color: "white" }}
            component="a"
            href={`tel:+${telefono}`}
          >
            <PhoneIcon />
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
