import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { FormHelperText, Link, Paper, Typography } from "@mui/material";
import tratamientos from "../tratamientos";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { generateURLForConsultaRapida } from "../services/TextService";

const FormConsultaRapida = () => {
  const [nombre, setNombre] = useState("");
  const [motivo, setMotivo] = useState("");
  const [tratamiento, setTratamiento] = useState(null);
  const [tipoTratamiento, setTipoTratamiento] = useState(null);
  const [zona, setZona] = useState("");

  const [nombreError, setNombreError] = useState(false);
  const [motivoError, setMotivoError] = useState(false);
  const [tratamientoError, setTratamientoError] = useState(false);
  const [tipoTratamientoError, setTipoTratamientoError] = useState(false);
  const [zonaError, setZonaError] = useState(false);

  const handleTratamiento = (e) => {
    setTratamiento(JSON.parse(e.target.value));
    setTipoTratamiento(null);
    setZona("");
  };

  const handleTipoTratamiento = (e) => {
    setTipoTratamiento(JSON.parse(e.target.value));
    setZona("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setNombreError(nombre === "");
    setMotivoError(motivo === "");
    setTratamientoError(tratamiento == null);
    tratamiento && setTipoTratamientoError(tipoTratamiento == null);
    tratamiento && tipoTratamiento && setZonaError(zona === "");

    if (nombre && motivo && tratamiento && tipoTratamiento && zona) {
      window.open(
        generateURLForConsultaRapida(
          nombre,
          motivo,
          tratamiento.tratamiento,
          tipoTratamiento.tipo,
          zona
        ),
        "_blank"
      );
    }
  };

  return (
    <Paper
      sx={{
        mt: -4,
        display: "flex",
        flexDirection: "column",
        width: { lg: "auto", md: "95%", sm: "95%", xs: "95%" },
      }}
      elevation={3}
    >
      <Typography variant="h5" align="center" sx={{ pt: 2 }}>
        Hacé tu consulta
      </Typography>
      <Stack
        sx={{ p: { xs: 1, md: 3 } }}
        direction={{ lg: "row", md: "column", sm: "column", xs: "column" }}
        spacing={2}
        justifyContent="center"
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
        <TextField
          id="nombre"
          label="Nombre"
          required
          variant="outlined"
          onChange={(e) => setNombre(e.target.value)}
          error={nombreError}
        />

        <FormControl sx={{ minWidth: "180px" }} error={motivoError}>
          <InputLabel id="label-motivo">Motivo</InputLabel>
          <Select
            labelId="label-motivo"
            id="motivo"
            label="Motivo"
            required
            onChange={(e) => setMotivo(e.target.value)}
          >
            <MenuItem value="Reservar turno">Reservar turno</MenuItem>
            <MenuItem value="Información">Información</MenuItem>
            <MenuItem value="Consultar precio">Consultar precio</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: "180px" }} error={tratamientoError}>
          <InputLabel id="label-tratamiento">Tratamiento</InputLabel>
          <Select
            labelId="label-tratamiento"
            id="tratamiento"
            label="Tratamiento"
            required
            onChange={handleTratamiento}
          >
            {tratamientos.map((tratamiento) => (
              <MenuItem value={JSON.stringify(tratamiento)}>
                {tratamiento.tratamiento}
              </MenuItem>
            ))}
          </Select>
          {tratamiento?.observaciones && (
            <FormHelperText>*{tratamiento.observaciones}</FormHelperText>
          )}
        </FormControl>

        {tratamiento && (
          <FormControl sx={{ minWidth: "180px" }} error={tipoTratamientoError}>
            <InputLabel id="label-tipo-tratamiento">Tipo</InputLabel>
            <Select
              labelId="label-tipo-tratamiento"
              id="tipo-tratamiento"
              label="Tipo"
              required
              onChange={handleTipoTratamiento}
            >
              {tratamiento.tipos.map((tipo) => (
                <MenuItem value={JSON.stringify(tipo)}>{tipo.tipo}</MenuItem>
              ))}
            </Select>
            {tipoTratamiento?.observaciones && (
            <FormHelperText>*{tipoTratamiento.observaciones}</FormHelperText>
          )}
          </FormControl>
        )}

        {tipoTratamiento && (
          <FormControl sx={{ minWidth: "180px" }} error={zonaError}>
            <InputLabel id="label-zona">Zona</InputLabel>
            <Select
              labelId="label-zona"
              id="zona"
              label="Zona"
              required
              onChange={(e) => setZona(e.target.value)}
            >
              {tipoTratamiento.zonas.map((zona) => (
                <MenuItem value={zona}>{zona}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl>
          <Button
            variant="contained"
            sx={{ height: 56 }}
            endIcon={<SendIcon />}
            type="submit"
          >
            Enviar
          </Button>
        </FormControl>
      </Stack>
      <Typography gutterBottom variant="caption" component="div" align="center">
        Para más consultas, podés contactarnos haciendo{" "}
        <Link component="a" href="#contacto">
          click acá
        </Link>
      </Typography>
    </Paper>
  );
};

export default FormConsultaRapida;
