import { Box, Button, Grid, Typography } from "@mui/material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

import whiteBackground from "../assets/white-background.jpg";
import hero4 from "../assets/hero-background4.jpg";
import { generateURLForGiftcard } from "../services/TextService";

const GiftCards = () => {
  return (
    <>
      <Typography variant="h2" align="center" color="primary" gutterBottom>
        Gift cards
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        columns={{ sm: 1, md: 2 }}
        sx={{
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Grid
          item
          xs={1}
          sx={{
            display: { xs: "none", md: "block" },
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${hero4})`,
            minHeight: "50vh",
          }}
        ></Grid>
        <Grid
          item
          xs={1}
          sx={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${whiteBackground})`,
            minHeight: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              align="center"
            >
              Tarjetas de regalo
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              component="div"
              align="center"
              sx={{px: 4}}
            >
              Regalá el presente de la relajación y la belleza. Nuestras
              tarjetas de regalo pueden ser usadas para cualquier servicio,
              incluyendo masajes y depilación.
            </Typography>
            <Button
              variant="contained"
              endIcon={<CardGiftcardIcon />}
              size="large"
              sx={{ mt: 4 }}
              component="a"
              target="_blank"
              href={generateURLForGiftcard()}
            >
              Comprar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GiftCards;
