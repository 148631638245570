import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Hero from "./components/Hero";
import FormConsultaRapida from "./components/FormConsultaRapida";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Servicios from "./components/Servicios";
import AboutUs from "./components/AboutUs";
import Separador from "./components/Separador";
import GiftCards from "./components/GiftCards";
import FAQ from "./components/FAQ";
import Contacto from "./components/Contacto";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#a076bb",
    },
    secondary: {
      main: "#f50057",
    },
  },
  typography: {
    h3: {
      fontFamily: "Great Vibes, cursive",
    },
    h2: {
      fontFamily: "Great Vibes, cursive",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header />
      <Container
        component="main"
        maxWidth="100%"
        disableGutters
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Hero />
        <FormConsultaRapida />
        <Separador />
        <Servicios />
        <Separador />
        <GiftCards />
        {/*<Separador />
        <Ubicacion />*/}
        <AboutUs />
        <Separador />
        <FAQ />
        <Contacto />
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
